import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import { Headline } from '../components/layout/typography';
import Form from '../components/form';
import Pagination from '../components/pagination';
import Post from '../components/post';

export default function BlogPage({
  data: {
    allGraphCmsPost: { nodes: posts, pageInfo },
  },
}) {
  return (
    <Layout title="Blog">
      <div className="bg-white pt-40 pb-20 px-4 sm:px-6 lg:pt-48 lg:pb-28 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <Headline className="text-7xl font-extrabold sm:text-9xl">
              Blog
            </Headline>
            <div
              id="subscribe"
              className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center"
            >
              <p className="text-xl text-gray-500">
                I regularly blog, speak on events or publish articles sharing my
                insights on building tech products. Subscribe to my newsletter
                to be a part of this journey!
              </p>
              <div className="mt-8 lg:mt-0 flex justify-center">
                <div className="bg-timothy-purple flex justify-center py-8 px-6 lg:px-16 rounded-2xl">
                  <Form />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {posts.map((post) => {
              return <Post key={post.id} post={post} />;
            })}
          </div>
        </div>
        <div className="mt-12">
          <Pagination prefix="blog" {...pageInfo} />
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPageQuery($skip: Int!, $limit: Int!) {
    allGraphCmsPost(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: date }
      filter: { stage: { eq: PUBLISHED } }
    ) {
      nodes {
        id
        date
        excerpt
        slug
        title
      }
      pageInfo {
        itemCount
        pageCount
        perPage
        totalCount
        currentPage
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;
